/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useMemo, Fragment, FC } from 'react';
import { Box, CardHeader, CardContent, IconButton, Typography, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Flex } from '../flex';
import { IData, IProps } from './types';
import { StyledContentCard } from './styled';

export const StaticContent: FC<IProps> = ({ data }) => {
	const { t: translate } = useTranslation();
	const { pathname } = useLocation();

	const [expanded, setExpanded] = useState<boolean[]>(Array(data.length).fill(false));

	const handleExpandClick = (index: number): void => {
		setExpanded((prevExpanded) => {
			const newExpanded = [...prevExpanded];
			newExpanded[index] = !newExpanded[index];
			return newExpanded;
		});
	};

	const isExpanded = (index: number): boolean => expanded[index];

	const baseTKey = useMemo(() => `pages.${pathname.replace('/', '')}.`, [pathname]);

	const t = useCallback(
		(key: string, index?: number, pres?: number[]) => {
			if (pres !== undefined) for (const pre of pres) key = key.replace('arr-item', `${pre + 1}`);
			key.includes('-arr-item') && index !== undefined && (key = key.replace('arr-item', `${index + 1}`));
			return translate(baseTKey.concat(key));
		},
		[translate, baseTKey]
	);
	const tItem = data[0] as IData;

	return (
		<Flex width='100%' justifyCenter column mb={5} px={3}>
			{Array.from(Array(data[0].length).keys()).map((item, index) => (
				<StyledContentCard
					key={`${data[0].title}-${item}`}
					onClick={(): void => handleExpandClick(index)}
					sx={{ width: '100%' }}
				>
					<CardHeader
						title={t(data[0].title, index)}
						action={
							<IconButton aria-expanded={isExpanded(index)} aria-label='show more'>
								{isExpanded(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							</IconButton>
						}
					/>
					<Collapse in={isExpanded(index)} timeout='auto' unmountOnExit>
						<CardContent>
							{Array.from(Array(tItem.data_arr[0].length[index]).keys()).map((n, indexDataArr) => (
								<Fragment key={`${tItem.data_arr[0].sub_title}-${n}`}>
									<Typography variant='h6' component='h3'>
										{t(tItem.data_arr[0].sub_title, indexDataArr, [index])}
									</Typography>
									{tItem.data_arr[0].content_arr && (
										<Box component='ul'>
											{Array.from(
												Array(
													tItem.data_arr[0].content_arr[0].length[index][indexDataArr]
												).keys()
											).map((v, indexContentArr) => (
												<Box
													component='li'
													key={`${tItem.data_arr[0].content_arr[0].key}-${v}`}
												>
													<Typography>
														{t(tItem.data_arr[0].content_arr[0].key, indexContentArr, [
															index,
															indexDataArr,
														])}
													</Typography>
												</Box>
											))}
										</Box>
									)}
								</Fragment>
							))}
						</CardContent>
					</Collapse>
				</StyledContentCard>
			))}
		</Flex>
	);
};

