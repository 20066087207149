import React, { FC, Suspense } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { RoutesContainer } from './routes';
import { AuthProvider, ErrorBoundary, NotificationsProvider, StoreProvider } from './providers';
import { SpotRatesProvider } from './providers/spot-rates';

// if you wish to change the font, replace the current .ttf file @/assets/font and update App.css and this theme config
const theme = createTheme({
	typography: {
		fontFamily: 'Montserrat, Medium',
	},
});

const App: FC = () => (
	<ThemeProvider theme={theme}>
		<Suspense fallback="Loading...">
			<ErrorBoundary>
				<StoreProvider>
					<SpotRatesProvider>
						<AuthProvider>
							<NotificationsProvider />
							<RoutesContainer />
						</AuthProvider>
					</SpotRatesProvider>
				</StoreProvider>
			</ErrorBoundary>
		</Suspense>
	</ThemeProvider>
);

export default App;
