import { keyframes } from '@emotion/react';
import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

const pulseAnimation = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgb(0 211 199);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 211, 199, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 211, 199, 0);
    }
`;
const Pulse = styled(Box as FC<BoxProps>)(() => ({
	display: 'block',
	position: 'absolute',
	zIndex: 1,
	top: 0,
	left: 0,
	width: '10px',
	height: '10px',
	borderRadius: '50%',
	background: '#00d3c7',
	'&:before': {
		content: '""',
		top: '0px',
		right: '0px',
		width: '10px',
		height: '10px',
		position: 'absolute',
		borderRadius: '50%',
		background: 'rgb(0 211 199)',
		cursor: 'pointer',
		boxShadow: '0 0 0 rgb(0 211 199)',
		animation: `${pulseAnimation} 1s linear infinite`,
		zIndex: 1,
	},
}));

const IconWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
}));

export { IconWrapper, Pulse };
