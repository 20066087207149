import { Component, ErrorInfo, ReactNode } from 'react';
import { IErrorBoundaryProps, IErrorBoundaryState } from './types';
import { AppError } from '@/components';

export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
	constructor(props: IErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	public static getDerivedStateFromError(_: Error): IErrorBoundaryState {
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		console.error('Uncaught error:', error, errorInfo);
	}

	public render(): ReactNode {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			return <AppError />;
		}

		return children;
	}
}
