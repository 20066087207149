/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IUtilsSlice } from './types';

const initialState: IUtilsSlice = {
	isDrawerOpen: false,
	language: 'ro'
};

const utilsSlice = createSlice({
	name: 'utils',
	initialState,
	reducers: {
		toggleDrawer: (state, action) => {
			state.isDrawerOpen = action.payload;
		},
		changeLanguage: (state, action) => {
			state.language = action.payload;
		},
	},
});

export const utilsReducer = utilsSlice.reducer;
export const utilsActions = utilsSlice.actions;
