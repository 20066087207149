import { AxiosError } from 'axios';
import { del, edit, get, post } from '@/utils';
import { IAppUser, IUpdateUser } from './types';

async function createAppUser(credentials: any): Promise<IAppUser[]> {
	try {
		const res = await post('api/protected/users', credentials);
		const data = res.data as unknown as { users: IAppUser[] };
		return data?.users || [];

	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get users.');
	}
}

async function fetchAppUsers(): Promise<IAppUser[]> {
	try {
		const res = await get('api/protected/users');
		const data = res.data as unknown as { users: IAppUser[] };
		return data?.users || [];

	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get users.');
	}
}

// async function getTempUser(userId: string): Promise<IAppUser> {
// 	try {
// 	  // Simulating finding the user directly from the UsersMock array
// 	  const user = UsersMock.find(el => el._id === userId);
// 	  if (!user) {
// 			throw new Error(`User with ID ${userId} not found.`);
// 	  }
// 	  return user;
// 	} catch (e) {
// 	  // Using a more concise way to express the error message fallback
// 	  throw new Error('Failed to get user.');
// 	}
// }

async function fetchSingleAppUser(userId: string): Promise<IAppUser> {
	try {
		const res = await get(`api/protected/users/${userId}`);
		const data = res.data as unknown as { user: IAppUser };
		return data?.user || {};

		// SIMULATE FETCHING
		// eslint-disable-next-line
		// await new Promise(resolve => setTimeout(resolve, 200));
		// const res = await getTempUser(userId);
		// const data = {user: res} as unknown as { user: IAppUser };
		// return data?.user || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get user.');
	}
}

async function deleteUser(userId: string): Promise<string> {
	try {
		const res = await del(`api/protected/users/${userId}`);
		const data = res.data as unknown as { message: string };
		return data?.message || 'User deleted';
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get user.');
	}
}

async function editUser(userId: string, user: IUpdateUser): Promise<number> {
	try {
		const res = await edit(`api/protected/users/${userId}`, {...user});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get user.');
	}
}

export { deleteUser, fetchAppUsers, fetchSingleAppUser, editUser, createAppUser };
