/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { edit, get, notify, post, readFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '@/utils';
import { AUTH_USER_STORAGE_KEY } from './constants';
import { AuthContext } from './context';
import { AuthState, IUser, IUserCredentials } from './types';
import { IUpdateUser } from '@/hooks/fetch-hooks/use-app-users/types';

const initialState: IUser = {
	_id: '',
	firstName: 'Test',
	lastName: 'Jhon',
	email: '',
	phone: '0760708130',
	role: undefined,
	status: undefined,
	isLoggedIn: false,
};

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [user, setUser] = useState<IUser | undefined>();
	const { t } = useTranslation<string>();
	const [authState, setAuthState] = useState<AuthState>(AuthState.Loading);

	useEffect(() => {
		try {
			setAuthState(AuthState.Loading);
			const userParsed = readFromLocalStorage<IUser>(AUTH_USER_STORAGE_KEY);

			if (userParsed) {
				setUser(userParsed);
				setAuthState(AuthState.SignedIn);
			} else setAuthState(AuthState.SignedOut);
		} catch (e) {
			console.log(e);
		}
	}, []);

	const registerUser = useCallback(async (credentials: any) => {
		try {
			setAuthState(AuthState.Loading);
			const response = await post<any, { user: IUser }>('api/auth/register', credentials);
			const userRes = response?.data?.user;
			if (userRes) {
				saveToLocalStorage(AUTH_USER_STORAGE_KEY, { ...userRes, isLoggedIn: true });
				if(userRes?.status !== 'inactive') {
					// setAuthState(AuthState.SignedIn);
					setUser({ ...userRes, isLoggedIn: true });
					notify.success('Registered succesfully!');
				} else {
					notify.success('Registered, wait for approval!');
					setUser({ ...userRes, isLoggedIn: false, status: 'inactive'});
					credentials.handleFinnaly?.();
				}
			}
		} catch (e) {
			const error = e as unknown as any;
			if (error.isNetworkError) {
				setAuthState(AuthState.NetworkError);
				notify.error(t('errors.networkError'));
			} else {
				setAuthState(AuthState.SignedOut);
				notify.error(error.response.data.message || 'Failed to login!');
			}
		}
		return undefined;
		
	}, []);

	const signIn = useCallback(async (credentials: IUserCredentials, path: string) => {
		try {
			setAuthState(AuthState.Loading);
			const response = await post<IUserCredentials, { user: IUser }>(`api/auth/${path}`, credentials);
			const userRes = response?.data?.user;
			if (userRes) {
				saveToLocalStorage(AUTH_USER_STORAGE_KEY, { ...userRes, isLoggedIn: true});
				if(userRes?.status !== 'inactive') {
					setUser({ ...userRes, isLoggedIn: true, status: 'active'});
					setAuthState(AuthState.SignedIn);
					notify.success('Logged in successfully!');
				} else {
					setUser({ ...userRes, isLoggedIn: false, status: 'inactive'});
					setAuthState(AuthState.SignedOut);
					credentials.handleFinnaly?.();
					notify.error('Wait for approval!');
				}
			}
		} catch (e) {
			const error = e as unknown as any;
			if (error.isNetworkError) {
				setAuthState(AuthState.NetworkError);
				notify.error(t('errors.networkError'));
			} else {
				setAuthState(AuthState.SignedOut);
				notify.error(error.response.data.message || 'Failed to login!');
			}
		}
		return undefined;
		
	}, []);

	const refreshAuthState = useCallback((parsedAuthState: AuthState) => {
		setAuthState(parsedAuthState);
		parsedAuthState === AuthState.SignedOut && setUser(initialState);
	}, []);

	const signOut = useCallback(async () => {
		try {
			const response = await get('api/auth/logout');
			// const response = {status: 200};
			if(response.status === 200) {
				setUser(initialState);
				removeFromLocalStorage(AUTH_USER_STORAGE_KEY);
				setAuthState(AuthState.SignedOut);
				window.location.reload();
				notify.success('Successfully logged out');
			}
		} catch (e) {
			notify.error('Failed to logout!');
		}
		// eslint-disable-next-line
	}, []);

	const updateUser = useCallback(async (updatedUser: IUpdateUser) => {
		try {
			setAuthState(AuthState.Loading);
			const response = await edit<IUpdateUser>('api/user', { ...updatedUser, company: undefined});
			const userRes = response?.data?.user;
			if (response.status === 200) {
				saveToLocalStorage(AUTH_USER_STORAGE_KEY, { ...userRes, isLoggedIn: true });
				setUser({ ...userRes, isLoggedIn: true });
				setAuthState(AuthState.SignedIn);
				notify.success('User updated successfully!');
			}
		} catch (e) {
			const error = e as unknown as any;
			if (error.isNetworkError) {
				setAuthState(AuthState.NetworkError);
				notify.error('Network error');
			} else {
				notify.error(error.response.data.message || 'Failed to update user!');
			}
		}
	}, []);

	// Memoize the context value
	const value = useMemo(
		() => ({
			user,
			registerUser,
			signIn,
			signOut,
			refreshAuthState,
			updateUser,
			authState,
		}),
		[user, signIn, signOut, authState, refreshAuthState, updateUser],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
