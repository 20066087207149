import { TinyEmitter } from 'tiny-emitter';
import { INotifier, Notification } from './types';

export class Notifier implements INotifier {
	private worker = new TinyEmitter();

	/**
   * Helps avoiding enabling twing the emitter
   */
	private enabled: boolean = false;

	public error(message: string): void {
		this.worker.emit(Notification.ERR, message);
	}

	public warning(message: string): void {
		this.worker.emit(Notification.WARN, message);
	}

	public info(message: string): void {
		this.worker.emit(Notification.INF, message);
	}

	public success(message: string): void {
		this.worker.emit(Notification.SUCC, message);
	}

	public enable(
		errorCallback: (message: string) => void,
		warningCallback: (message: string) => void,
		infoCallback: (message: string) => void,
		successCallback: (message: string) => void,
	): void {
		if (!this.enabled) {
			this.worker.on(Notification.ERR, errorCallback);
			this.worker.on(Notification.WARN, warningCallback);
			this.worker.on(Notification.INF, infoCallback);
			this.worker.on(Notification.SUCC, successCallback);
			this.enabled = true;
		}
	}
}
