import React, { ReactNode } from 'react';

interface ListComponentProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
}

export const ListComponent = <T,>({
	items,
	renderItem,
}: ListComponentProps<T>): JSX.Element => <>{React.Children.toArray(items.map(renderItem))}</>;
