import { FC } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '@/utils';
import { HeaderContainer } from './styled';
import { UserSide } from './user-side/component';
import { Logo } from '../logo';

export const Header: FC = () => {
	const { isDrawerOpen } = useAppSelector((state) => state.utils);

	return (
		<HeaderContainer isDrawerOpen={isDrawerOpen}>
			<Box>
				<Logo />
			</Box>
			<UserSide />
		</HeaderContainer>
	);
};
