import { useCallback, useEffect, useState } from 'react';
import { createAppUser, deleteUser, editUser, fetchAppUsers, fetchSingleAppUser } from './actions';
import { AppUsersReturnType, IAppUser, IUpdateUser } from './types';
import { notify, stateSetter } from '@/utils';

export function useAppUsers<T extends IAppUser | IAppUser[]>(
	getAppUsersOnRender: boolean | undefined = undefined,
	appUserId: string | undefined = undefined,
): AppUsersReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const postAppUser = useCallback(
		async (credentials: any) => {
			try {
				setLoading(true);
				const response = await createAppUser(credentials);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAppUsers = useCallback(
		async () => {
			try {
				setLoading(true);
				const response = await fetchAppUsers();
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAppUser = useCallback(
		async (userId: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleAppUser(userId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeUser = useCallback(
		async (userId: string) => {
			try {
				setLoading(true);
				const response = await deleteUser(userId);
				notify.success(response);
				getAppUsers();
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const updateUser = useCallback(
		async (userId: string, userData: IUpdateUser, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await editUser(userId, userData);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Failed to update admin!');
				}
				getAppUsers();
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getAppUsersOnRender) {
			getAppUsers();
		}
		// eslint-disable-next-line
	}, [getAppUsersOnRender]);

	useEffect(() => {
		if (appUserId) {
			getAppUser(appUserId);
		}
		// eslint-disable-next-line
	}, [appUserId]);

	return {
		data: data || ([] as IAppUser[] as T),
		setData: setData as stateSetter<T>,
		loading,
		getAppUser,
		getAppUsers,
		removeUser,
		updateUser,
		postAppUser
	};
}