
const containerOptions = [
	{ label: '20ft Standard', isoCode: '22G1' },
	{ label: '20ft Reefer', isoCode: '22R0' },
	{ label: '40ft Standard', isoCode: '42G1' },
	{ label: '40ft Reefer', isoCode: '42R0' },
	{ label: '40ft High Cube', isoCode: '45G1' },
	{ label: '45ft High Cube', isoCode: 'L5G1' },
];

export { containerOptions };