import React, { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CookieConsent from 'react-cookie-consent';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Flex } from '../flex';

export const CookieConsentModal: FC = () => {
	const navigate = useNavigate();
	return (
		<CookieConsent
			location="bottom"
			buttonText="Accept"
			ButtonComponent={Button}
			cookieName="cookieConsent"
			style={{ background: '#2B373B'}}
			buttonStyle={{ color: '#4e503b', fontSize: '13px', borderRadius: '4px' }}
			expires={150}
		>
			<Flex gap={1}>
				<Typography>
					This website uses cookies to ensure you get the best experience on our website.
				</Typography>
				<Button sx={{color: '#4e503b', backgroundColor: '#ffd42d', fontSize: '13px'}} 
					variant='contained' onClick={() => navigate('/cookie-policy')}>
					See cookie page
				</Button>
			</Flex>
		</CookieConsent>
	);
};
