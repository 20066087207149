import { BoxProps } from '@mui/material';
import { ReactNode } from 'react';

export interface ICardCRProps extends BoxProps {
    children: ReactNode;
    width?: string;
    height?: string;
    column?: boolean;
    justifyCenter?: boolean;
    justifyEnd?: boolean;
    alignStart?: boolean;
    overflowY?: string;
    imageSrc?: string;
    marginTop?: string;
    overflowX?: string;
    transparent?: boolean;
}

export const extendedProps = [
	'width',
	'height',
	'column', 
	'justifyCenter',
	'justifyEnd', 
	'alignStart', 
	'overflowY', 
	'marginTop',
	'overflowX',
	'transparent',
];