// LOCAL STORAGE ACTIONS

export const saveToLocalStorage = (key: string, value: NonNullable<unknown>): void => {
	try {
		const serializedValue = JSON.stringify(value);
		localStorage.setItem(key, serializedValue);
	} catch (e) {
		console.error(e);
	}
};

export const readFromLocalStorage = <T>(key: string): T | undefined => {
	try {
		const value = localStorage.getItem(key);
		if (value) return JSON.parse(value);
	} catch (e) {
		console.error(e);
		return undefined;
	}
	return undefined;
};

export const removeFromLocalStorage = (key: string): void => {
	try {
		localStorage.removeItem(key);
	} catch (e) {
		console.error(e);
	}
};
