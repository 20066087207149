import { AxiosError } from 'axios';
import { get } from '@/utils';

async function fetchPorts(input: string): Promise<any> {
	try {
		if (input.length < 3) return [];
		const res = await get(`api/common/locations?cityName=${input}`);
		const { data } = res;
		console.log(data.placesMaersk);
		return data?.placesMaersk || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get ports.');
	}
}

export { fetchPorts };