const stringToColor = (string: string): string => {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
};

interface IStringAvatarProps {
	sx: {
		bgcolor: string;
		width: string;
		height: string;
	},
	children: string;
}

const stringAvatar = (name: string, dimension?: string): IStringAvatarProps => ({
	sx: {
		bgcolor: stringToColor(name),
		width: dimension || '112px',
		height: dimension || '112px'
	},
	children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
});

export default stringAvatar;