import { styled } from '@mui/material';
import { FC } from 'react';
import { Flex, FlexProps } from '@/components';

export const PageContainer = styled(Flex as FC<FlexProps>)(({ theme }) => ({
	width: '100%',
	height: '100%',
	minHeight: '89vh',
	borderRadius: '16px',
	backgroundColor: theme.palette.common.white,
	boxShadow: theme.shadows[3],
	flexDirection: 'column',
	padding: '0 16px',
	overflowY: 'auto'
}));