import { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from '@/utils';

const persistedStore = persistStore(store);

export const StoreProvider: FC<{ children: ReactNode }> = ({ children }) => (
	<Provider store={store}>
		<PersistGate persistor={persistedStore}>{children}</PersistGate>
	</Provider>
);
