import { FC, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuthContext } from '@/providers';
import { AuthState } from '@/providers/auth';
import { Loadable } from '../components';
import { AdminRoute } from './admin';
import { AuthRoute } from './auth';
import { PendingRoute } from './pending';
import { CommonRoute } from './common';
import { UserModeratorCommonRoute } from './user-moderator';
import { ModeratorRoute } from './moderator';

// MODERATOR ROUTES
const CreateUser = Loadable(lazy(() => import('../views/moderator/create-user')));
const Employees = Loadable(lazy(() => import('../views/moderator/employees')));

// COMMON ROUTES
const RequestShipment = Loadable(lazy(() => import('../views/common/request-shipment')));
const RequestShipping = Loadable(lazy(() => import('../views/common/request-shipping')));
const TermsAndConditions = Loadable(lazy(() => import('../views/common/terms-and-conditions')));
const Contact = Loadable(lazy(() => import('../views/common/contact')));
const PrivacyAndPolicy = Loadable(lazy(() => import('../views/common/privacy-and-policy')));
const Cookies = Loadable(lazy(() => import('../views/common/cookies')));
const AboutUsPage = Loadable(lazy(() => import('../views/common/about-us')));

// ADMIN ROUTES
// const AdminPage = Loadable(lazy(() => import('../views/admin')));
const UsersAppPage = Loadable(lazy(() => import('../views/admin/app-users/all-users')));
const UsersAppInfoPage = Loadable(lazy(() => import('../views/admin/app-users/user-info')));

const AdminAppPage = Loadable(lazy(() => import('../views/admin/admin-users/all-admins')));
const AdminAppInfoPage = Loadable(lazy(() => import('../views/admin/admin-users/admin-info')));
const CreateAdminPage = Loadable(lazy(() => import('../views/admin/admin-users/create-admin')));

const AdminAllCompanies = Loadable(lazy(() => import('../views/admin/companies/all-companies')));
const CompanyInfo = Loadable(lazy(() => import('../views/admin/companies/company-info')));

const AdminProviderInfo = Loadable(lazy(() => import('../views/admin/companies/old-folders-providers/info-providers')));
const AdminProviderCreate = Loadable(lazy(() => 
	import('../views/admin/companies/old-folders-providers/create-provider')));

const Requests = Loadable(lazy(() => import('../views/admin/requests/main-page')));
const RequestUserInfo = Loadable(lazy(() => import('../views/admin/requests/single-request')));

const AllSearches = Loadable(lazy(() => import('../views/admin/all-searches')));

const Bookings = Loadable(lazy(() => import('../views/admin/bookings/all-bookings')));
const BookingInfo = Loadable(lazy(() => import('../views/admin/bookings/booking-info')));

// AUTH ROUTES
const LoginPage = Loadable(lazy(() => import('../views/auth/login')));
const UserLoginPage = Loadable(lazy(() => import('../views/auth/user-login')));
const ForgotPassword = Loadable(lazy(() => import('../views/auth/user-login/forgot-password')));
const ResetPassword = Loadable(lazy(() => import('../views/auth/user-login/reset-password')));
const UserRegisterPage = Loadable(lazy(() => import('../views/auth/user-register')));

// USER ROUTES
const ComonPage = Loadable(lazy(() => import('../views/common')));
const Notfound = Loadable(lazy(() => import('../views/common/not-found')));
const UserProfile = Loadable(lazy(() => import('../views/user/profile')));
const UserDashboard = Loadable(lazy(() => import('../views/user/dashboard')));
const Billing = Loadable(lazy(() => import('../views/user/billing')));
const NewSearch = Loadable(lazy(() => import('../views/user/new-search')));
const Payments = Loadable(lazy(() => import('../views/user/payments')));
const Searches = Loadable(lazy(() => import('../views/user/searches')));
const Subscription = Loadable(lazy(() => import('../views/user/subscription')));
const MyOrdersPage = Loadable(lazy(() => import('../views/user/my-orders')));

// PENDING APPROVAL
const Pending = Loadable(lazy(() => import('../views/pending')));

export const RoutesContainer: FC = () => {
	const { refreshAuthState } = useAuthContext();

	useEffect(() => {
		const handleUnauthenticated = (): void => {
		  refreshAuthState(AuthState.SignedOut);
		};
		window.addEventListener('unauthenticated', handleUnauthenticated);
	
		return (): void => {
		  window.removeEventListener('unauthenticated', handleUnauthenticated);
		};
	  }, [refreshAuthState]);

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<CommonRoute/>}>
					<Route path="/" element={<ComonPage />} />
					<Route path="/shipment" element={<RequestShipment />} />
					<Route path="/request-shipping" element={<RequestShipping />} />
					<Route path="*" element={<Notfound />} />
					<Route path="/shipping" element={<RequestShipment />} />
					<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
					<Route path="/privacy-and-policy" element={<PrivacyAndPolicy />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/cookie-policy" element={<Cookies />} />
					<Route path="/about-us" element={<AboutUsPage />} />
				</Route>
				<Route path='/' element={<PendingRoute redirectPath='/auth' />}>
					<Route path='/pending' element={<Pending />} />
				</Route>
				<Route path="/auth" element={<AuthRoute redirectPath='/user' />}>
					<Route path="/auth" element={<UserLoginPage />} />
					<Route path="/auth/user/forgot-password" element={<ForgotPassword />} />
					<Route path="/auth/user/reset-password" element={<ResetPassword />} />
					<Route path="/auth/user/register" element={<UserRegisterPage />} />	
					<Route path="/auth/admin/login" element={<LoginPage />} />
				</Route>
				<Route path='/moderator' element={<ModeratorRoute redirectPath='/user'/>}>
					<Route path="/moderator/create-user" element={<CreateUser />} />	
					<Route path="/moderator/employees" element={<Employees />} />	
				</Route>
				<Route path="/user" element={<UserModeratorCommonRoute redirectPath='/admin' />} >
					<Route path="/user/profile" element={<UserProfile/>} />
					<Route path="/user" element={<UserDashboard/>} />
					<Route path="/user/billing" element={<Billing/>} />
					<Route path="/user/new-search" element={<NewSearch	/>} />
					<Route path="/user/payments" element={<Payments/>} />
					<Route path="/user/searches" element={<Searches/>} />
					<Route path="/user/subscription" element={<Subscription/>} />
					<Route path="/user/orders" element={<MyOrdersPage/>} />
				</Route>
				<Route path="/admin" element={<AdminRoute redirectPath='/auth' />}>
					<Route path="/admin" element={<UserDashboard />} />
					<Route path="/admin/users-app" element={<UsersAppPage />} />
					<Route path="/admin/users-app/:id" element={<UsersAppInfoPage />} />
					<Route path="/admin/users-admin" element={<AdminAppPage />} />
					<Route path="/admin/users-admin/:id" element={<AdminAppInfoPage />} />
					<Route path="/admin/users-admin/create" element={<CreateAdminPage />} />
					<Route path="/admin/admin-companies" element={<AdminAllCompanies />} />
					<Route path="/admin/admin-companies/:id" element={<CompanyInfo />} />
					<Route path="/admin/admin-providers/:id" element={<AdminProviderInfo />} />
					<Route path="/admin/admin-providers/create" element={<AdminProviderCreate />} />
					<Route path="/admin/requests" element={<Requests />} />
					<Route path="/admin/requests/:id" element={<RequestUserInfo />} />
					<Route path="/admin/all-searches" element={<AllSearches />} />
					<Route path="/admin/bookings" element={<Bookings />} />
					<Route path="/admin/bookings/:id" element={<BookingInfo />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};
