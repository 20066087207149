import { SearchFormData } from '@/components/shipping-search/types';
import { ICompany, IUpdateUser } from '@/hooks/fetch-hooks/use-app-users/types';

export enum AuthState {
  Loading,
  SignedOut,
  SignedIn,
  NetworkError,
}

export type UserRole = 'superadmin' | 'moderator' | 'user' | 'admin' | undefined;

export interface IUser {
  _id: string | undefined;
  role: UserRole;
  email: string;
  firstName: string;
  lastName: string;
  history?: SearchFormData[];
  phone?: string;
  status?: string;
  company?: ICompany;
  isLoggedIn?: boolean;
}

/**
 * Type used to describe the data needed for logging in
 */
export interface IUserCredentials {
  email: string;
  password: string;
  role?: UserRole;
  handleFinnaly?: () => void;
}

export interface IAuthContext {
  authState: AuthState;
  user: IUser | undefined;
  registerUser: (data: any) => Promise<any | undefined>;
  signIn: (data: IUserCredentials, path: string) => Promise<any | undefined>;
  signOut: () => Promise<void>;
  refreshAuthState: (authState: AuthState) => void;
  updateUser: (data: IUpdateUser) => Promise<any | undefined>;
}
