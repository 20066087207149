import { alpha, ListItem, ListItemProps, styled } from '@mui/material';
import { FC } from 'react';

export const CollapsedListItem = styled(ListItem as FC<ListItemProps>)(({ theme }) => ({
	minHeight: 36,
	height: 44,
	justifyContent: 'initial',
	cursor: 'pointer',
	':hover': { 
		backgroundColor: alpha(theme.palette.grey[800], 0.1)
	}
}));