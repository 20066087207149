import {
	styled, TableCell, TableCellProps, TableContainer,
	TableContainerProps, TableRow, TableRowProps
} from '@mui/material';
import { FC } from 'react';

export const TableWrap = styled(TableContainer as FC<TableContainerProps>)(({ theme }) => ({
	backgroundColor: theme.palette.common.white
}));

export const CustomTableRow = styled(TableRow as FC<TableRowProps>)(() => ({
	height: '68px',
	maxHeight: '68px',
	verticalAlign: 'bottom'
}));

export const CustomTableCell = styled(TableCell as FC<TableCellProps>)(() => ({
	height: '32px',
	maxHeight: '32px',
	marginTop: 'auto',
	padding: '0px 8px 8px 8px',
	fontSize: '14px',
}));
