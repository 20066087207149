import { Outlet, Navigate } from 'react-router-dom';
import { FC } from 'react';
import { IRouteProps } from '../types';
import { useAuthContext } from '@/providers';
import { AuthWrapper } from '@/components/wrapper';
import { CookieConsentModal, Flex } from '@/components';
import UserHeader from '@/components/user-header';

export const AuthRoute: FC<IRouteProps> = ({ redirectPath }) => {
	const { user } = useAuthContext();
	if (user?.role === undefined || user?.status === 'inactive')
		return (
			<>
				<UserHeader />
				<AuthWrapper>
					<Flex justifyCenter sx={{ alignItems: 'center', height: '100%`' }}>
						<Outlet />
						<CookieConsentModal />
					</Flex>
				</AuthWrapper>
			</>
		);
	if (user?.status === 'inactive'){ 
		return <Navigate to="/pending" replace />;
	}
	return <Navigate to={redirectPath} replace />;
};
