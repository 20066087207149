import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

interface IMainLayoutProps extends BoxProps {
	isDrawerOpen: boolean;
	headerSize?: number;
}

const exclude =['isDrawerOpen'];

export const MainLayoutBase: FC<IMainLayoutProps> = styled(Box as FC<IMainLayoutProps>, {
	shouldForwardProp: (prop: string) => !exclude.includes(prop),
})(({ isDrawerOpen }) => ({
	width: '100%',
	height: '100%',
	position: 'relative',
	paddingTop: 90,
	paddingLeft: isDrawerOpen ? 286 : 102,
	paddingBottom: 16,
	paddingRight: 16,
	transition: 'all 0.2s ease-in-out'
}));

export const MainLayoutContent: FC<BoxProps> = styled(Box as FC<BoxProps>)(({ theme }) => ({
	width: '100%',
	height: '100%',
	minHeight: '89vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	position: 'relative',
	transition: 'all 0.2s ease-in-out',
	borderRadius: '16px',
	boxShadow: theme.shadows[10]
}));
