import dayjs from 'dayjs';
import { createContext, useContext } from 'react';

export const SpotRatesContext = createContext<any>({
	spotRates: [],
	setSpotRates: () => {},
	originFrom: '',
	setOriginFrom: () => {},
	destinationTo: '',
	setDestinationTo: () => {},
	commodity: '',
	setCommodity: () => {},
	departureDate: dayjs().format('YYYY-MM-DD'),
	setDepartureDate: () => {},
	arrivalDate: dayjs().format('YYYY-MM-DD'),
	setArrivalDate: () => { },
	containerType: '',
	setContainerType: () => {},
	weight: 0,
	setWeight: () => {},
	numberofContainers: 0,
	setNumberofContainers: () => {},
	formDefault: {},
	setFormDefault: () => {},
});

export const useSpotRates = (): any => {
	const context = useContext(SpotRatesContext);
	if (context === undefined) {
		throw new Error('useSpotRates must be used within a SpotRatesProvider');
	}
	return context;
};
