import { useCallback, useState } from 'react';
import { fetchPorts } from './actions';
import { notify } from '@/utils';
import { PortsReturnType } from './types';

export function usePorts(
): PortsReturnType {
	const [ports, setPorts] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);

	const getPorts = useCallback(
		async (input: string) => {
			try {
				setLoading(true);
				const response = await fetchPorts(input);
				setPorts(response);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setPorts]
	);

	return {
		ports,
		setPorts,
		loading,
		getPorts,
	};
}
