import { createContext, useContext } from 'react';
import { AuthState, IAuthContext } from './types';

export const AuthContext = createContext<IAuthContext>({
	authState: AuthState.SignedOut,
	user: undefined,
	registerUser: async () => undefined,
	signIn: async () => undefined,
	signOut: async () => {},
	refreshAuthState: () => {},
	updateUser: async () => undefined,
});

export const useAuthContext = (): IAuthContext => useContext(AuthContext);
