import axios from 'axios';
import { notify } from '../notify';
import { removeFromLocalStorage } from '../storage';
import { AUTH_USER_STORAGE_KEY } from '@/providers/auth/constants';

const request = axios.create({
	withCredentials: true,
});

request.interceptors.response.use(
	(response) => {
		if (response.status === 401) {
			removeFromLocalStorage(AUTH_USER_STORAGE_KEY);
			window.dispatchEvent(
				new CustomEvent('unauthenticated', {
					detail: {
						message: 'User is not authenticated',
					},
				}),
			);
		} else if (response.status === 403) {
			window.dispatchEvent(
				new CustomEvent('forbidden', {
					detail: {
						message: 'User is forbidden',
					},
				}),
			);
		}
		return response;
	},
	(error) => {
		if (error.response) {
			if (error.response.status === 401) {
				removeFromLocalStorage(AUTH_USER_STORAGE_KEY);
				window.dispatchEvent(
					new CustomEvent('unauthenticated', {
						detail: {
							message: 'User is not authenticated',
						},
					}),
				);
			} else if (error.response.status === 403) {
				window.dispatchEvent(
					new CustomEvent('forbidden', {
						detail: {
							message: 'User is forbidden',
						},
					}),
				);
			} else if (error.response.status === 404) {
				window.dispatchEvent(
					new CustomEvent('notfound', {
						detail: {
							message: 'Resource not found',
						},
					}),
				);
			} else if (error.response.status === 400) {
				window.dispatchEvent(
					new CustomEvent('badrequest', {
						detail: {
							message: 'Bad request',
						},
					}),
				);
			} else if (error.response.status === 409) {
				window.dispatchEvent(
					new CustomEvent('conflict', {
						detail: {
							message: 'Conflict',
						},
					}),
				);
			} else if (error.response.status === 500) {
				window.dispatchEvent(
					new CustomEvent('internalservererror', {
						detail: {
							message: 'Internal server error',
						},
					}),
				);
				notify.error('Internal server error!');
			}
		} else {
			// eslint-disable-next-line no-param-reassign
			error.isNetworkError = true;
			notify.error('Network error!');
		}
		if (error.response.data?.message) {
			notify.error(error.response.data.message);
		}
		return Promise.reject(error);
	},
);

export default request;
