import { Box, alpha, styled } from '@mui/material';
import { FC } from 'react';
import { ICardCRProps, extendedProps } from './types';

export const StyledCard = styled(Box as FC<ICardCRProps>,  {
	shouldForwardProp: (prop: string) => !extendedProps.includes(prop),
})(({ 
	theme,
	width = '70%', 
	height = '100%', 
	column = false, 
	justifyCenter = false, 
	justifyEnd = false,  
	alignStart = false,
	transparent = false
	  }) => ({
	width: `${width}`,
	height: `${height}`,
	borderRadius: '16px',
	display: 'flex',
	alignItems: alignStart ? 'flex-start' : 'center',
	justifyContent: justifyCenter ? 'center' : (justifyEnd && 'flex-end') || 'flex-start',
	backgroundColor: transparent ? alpha(theme.palette.common.white, 0.5): '#fff',
	boxShadow: theme.shadows[2],
	flexDirection: column ? 'column' : 'row',
	padding: '40px',
	overflowY: 'hidden', 
	overflowX: 'hidden',
	[theme.breakpoints.down('md')]: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: '10px',
		overFlow: 'auto'
	},
}));