export const capitalize = (str: string): string => `${str.at(0)?.toUpperCase()}${str.slice(1)}`;

export const minimize = (str: string): string => `${str.at(0)?.toLowerCase()}${str.slice(1)}`;

export const { isArray } = Array;

export function ifIsArray<T>(el: any): T[] {
	return (isArray(el) ? [...el] : []) as T[];
}

export { v4 as uuid } from 'uuid';

type NestedObject = Record<string, any>;

export function hasEmptyOrNull(obj: NestedObject): boolean {
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key];
			if (value === null || value === '') {
				return true;
			} if (typeof value === 'object' && value !== null) {
				// Recursive call for nested objects
				if (hasEmptyOrNull(value)) {
					return true;
				}
			}
		}
	}
	return false;
}

export const getTimeElapsed = (timestamp: string): string => {
	const now = new Date();
	const past = new Date(timestamp);

	if (Number.isNaN(past.getTime())) {
		throw new Error(`Invalid timestamp: ${timestamp}`);
	}

	const isSameDay = now.toDateString() === past.toDateString();
	const isYesterday =
		now.getDate() - past.getDate() === 1 &&
		now.getMonth() === past.getMonth() &&
		now.getFullYear() === past.getFullYear();

	const timeFormat = new Intl.DateTimeFormat('ro', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	}).format(past);

	if (isSameDay) {
		return timeFormat;
	} if (isYesterday) {
		return `Ieri ${timeFormat}`;
	} 
	return new Intl.DateTimeFormat('ro', {
		day: 'numeric',
		month: 'long',
	}).format(past);
	
};