import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { CookieConsentModal, Flex } from '@/components';
import UserHeader from '@/components/user-header';
import Footer from '@/components/footer';

export const CommonRoute: FC = () => (
	<Box sx={{ backgroundColor: '#F8F9FD' }}>
		<Flex>
			<UserHeader />
			<Outlet />
			<CookieConsentModal />
			<Footer />
		</Flex>
	</Box>
);
