import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { FC, useState } from 'react';
import { Typography, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '@/providers';
import { Notifications } from '../header/user-side/notifications/component';
import ProfileMenu from './profile-menu';
import TranslationDropdown from '../translation-dropdown';
import { Logo } from '../logo';

export const UserHeader: FC = () => {
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClickLogin = (): void => {
		navigate('/auth');
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	return (
		<AppBar position="absolute" sx={{ backgroundColor: 'transparent', color: '#000', boxShadow: 'none' }}>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Box >
						<Link to="/">
							<Logo />
						</Link>
					</Box>
					<Box sx={{ flexGrow: 10 }} /> 
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						{user?.role === undefined || user?.status === 'inactive' ? (
							<Button onClick={handleClickLogin}>{t('common.buttons.login-register')}</Button>
						) : (
							<Box display="flex" alignItems="center">
								<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
									<Link to="/user" style={{ textDecoration: 'none' }}>
										<Typography>Dashboard</Typography>
									</Link>
								</Box>
								<ProfileMenu />
								<NotificationsIcon />
							</Box>
						)}
						<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
							<ArrowDropDownIcon />
						</Button>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem onClick={handleClose}>
								<TranslationDropdown />
							</MenuItem>
							{(user?.role || user?.status === 'inactive') && 
							<MenuItem onClick={handleClose}>
								<Link to="/user" style={{ textDecoration: 'none' }}>
									<Typography>Dashboard</Typography>
								</Link>
							</MenuItem>}
						</Menu>
					</Box>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						{user?.role === undefined || user?.status === 'inactive' ? (
							<Button sx={{ my: 2, display: 'block' }} onClick={handleClickLogin}>
								{t('common.buttons.login-register')}
							</Button>
						) : (
							<Box marginTop="13px" display="flex">
								<Box display="flex" alignItems="center">
									<Link to="/user" style={{ textDecoration: 'none' }}>
										<Typography>Dashboard</Typography>
									</Link>
								</Box>
								<ProfileMenu />
								<Notifications />
							</Box>
						)}
						<Box display="flex" alignItems="center">
							<TranslationDropdown />
						</Box>
						
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
