import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

interface CommonSearchWrapperProps extends BoxProps {
	isContainerDetails?: boolean;
}

const CommonSearchWrapper = styled(Box as FC<CommonSearchWrapperProps>,  {
	shouldForwardProp: (prop: string) => !['isContainerDetails'].includes(prop),
})(({ theme, isContainerDetails = false }) => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	padding: theme.spacing(1),
	flexDirection: isContainerDetails ? 'column' : 'row',
	gap: theme.spacing(2),
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
	},
}));

export { CommonSearchWrapper };