import {
	Box,
	BoxProps,
	CSSObject,
	DrawerProps,
	Drawer as MuiDrawer,
	Theme,
	styled
} from '@mui/material';
import { FC } from 'react';
// import { OrangeShapes } from '@/assets';

export const drawerWidth = '250px';

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	width: '66px',
});

const Drawer: FC<DrawerProps> = styled(MuiDrawer, { shouldForwardProp: (prop: string) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		'.MuiPaper-root': {
			margin: theme.spacing(2),
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.white,
			height: 'calc( 100% - 32px )',
			width: 'calc( 100% - 32px )',
			borderRadius: '16px',
			boxShadow: theme.shadows[10],
			padding: theme.spacing(0, 0, 2, 0),
			// overflow: 'visible',
			// backgroundImage: `url(${OrangeShapes})`,
			// backgroundSize: 'cover',
			// backgroundPosition: 'center',
			'.MuiSvgIcon-root': {
				color: theme.palette.common.black
			},
			'.MuiTypography-root': {
				color: theme.palette.common.black,
				fontSize: '16px',
				marginLeft: theme.spacing(1),
			},
			'.MuiButtonBase-root': {
				height: '44px',
			},
			'.MuiListItemIcon-root': {
				margin: open ? 'inherit' : '0px auto'
			}
		},
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

const MenuWrapper: FC<BoxProps> = styled(Box)({
	overflowY: 'auto',
	height: 'calc(100% - 120px)',
	paddingTop: '16px',
	'::-webkit-scrollbar': {
		width: '8px',
	},
	'::-webkit-scrollbar-thumb': {
		border: 'none',
	},
});

export { Drawer, MenuWrapper };
