/* eslint-disable no-nested-ternary */
import { FC, Fragment } from 'react';
import { utilsActions } from '@/store/utils/slice';
import { useAppDispatch, useAppSelector, uuid } from '@/utils';
import AdminMenu from './data/admin-menu.json';
import UserMenu from './data/user-menu.json';
import ModeratorMenu from './data/moderator-menu.json';
import { DrawerFooter } from './drawer-footer';
import { DrawerHeader } from './drawer-header';
import { MenuTabs } from './menu-items';
import { Drawer, MenuWrapper } from './styled';
import { useAuthContext } from '@/providers';

export interface MenuItem {
  type?: string;
  title: string;
  icon: string;
  url: string;
  children?: MenuItem[];
}

export const SideDrawer: FC = () => {
	const { isDrawerOpen } = useAppSelector((state) => state.utils);
	const { user } = useAuthContext();
	const dispatch = useAppDispatch();

	return (
		<Drawer variant="permanent" open={isDrawerOpen} >
			<DrawerHeader
				open={isDrawerOpen}
				closeDrawer={(): { payload: undefined; type: 'utils/toggleDrawer' } =>
					dispatch(utilsActions.toggleDrawer(!isDrawerOpen))
				}
			/>
			{/* ****************| MENU ACTIONS |************************* */}
			<MenuWrapper>
				{user?.role === 'admin' || user?.role === 'superadmin' ? (
					AdminMenu.map(
						(el) =>
							!!el.children?.length && (
								<Fragment key={`menu-tabs-${uuid()}`}>
									<MenuTabs menuItems={el?.children || []} />
								</Fragment>
							),
					)
				) : user?.role === 'user' ? (
					UserMenu.map(  
						(el) =>
							!!el.children?.length && (
								<Fragment key={`menu-tabs-${uuid()}`}>
									<MenuTabs menuItems={el?.children || []} />
								</Fragment>
							),
					)
				) : (
					ModeratorMenu.map(
						(el) =>
							!!el.children?.length && (
								<Fragment key={`menu-tabs-${uuid()}`}>
									<MenuTabs menuItems={el?.children || []} />
								</Fragment>
							),
					)
				)}
			</MenuWrapper>
			<DrawerFooter isOpen={isDrawerOpen} />
		</Drawer>
	);
};
