import { Dialog, DialogActions, DialogContent,  Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { FC } from 'react';
import { SimpleDialogTitle } from './styled';
import { ISimpleDialogProps } from './types';

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

export const SimpleDialogTransition: FC<ISimpleDialogProps> = ({
	open,
	setOpen,
	title, 
	children,
	ctaSection,
	titleProps,
	maxWidth = '800px'
}) => {

	const handleClose = (): void => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="delete-dialog-description"
			PaperProps={{ sx: { maxWidth: maxWidth || '800px'}}}
		>
			<SimpleDialogTitle {...titleProps}>{title}</SimpleDialogTitle>
			<DialogContent >
				{children}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', mb: 1}}>
				{ctaSection}
			</DialogActions>
		</Dialog>
	);
};