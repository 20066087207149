import React from 'react';
import { Box, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateBookDetailsProps } from '../../types';
import { CommonSearchWrapper } from '../styled';

export const DateBookDetails: React.FC<DateBookDetailsProps> = 
({ control, setValue, setDepartureDate, setArrivalDate, departureDate }) => {
	
	const { t } = useTranslation();

	const dateInputs = [
		{
			name: 'departureDate',
			label: t('components.search.dateBookDetails.inputs.departure'),
			setValueFunc: setDepartureDate,
		},
		{
			name: 'arrivalDate',
			label: t('components.search.dateBookDetails.inputs.arrival'),
			setValueFunc: setArrivalDate,
		}
	];

	return (
		<>
			<Box textAlign='center' flexWrap="wrap">
				<Typography variant='h6'>{t('components.search.dateBookDetails.title')}</Typography>
			</Box>
			<CommonSearchWrapper>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					{dateInputs.map((input) => (
						<Controller
							key={input.name}
							name={input.name}
							control={control}
							rules={{ required: true }}
							render={({ field: { onChange, value } }) => (
								<DatePicker
									label={input.label}
									sx={{ width: '100%', borderRadius: '16px' }}
									onChange={( newValue ) => {
										const dateValue = newValue?.format('YYYY-MM-DD') as string;
										setValue(input?.name as 'departureDate' | 'arrivalDate', dateValue);
										input.setValueFunc(dateValue);
										onChange(dateValue);
									}}
									minDate={input.name === 'departureDate' ? dayjs(new Date()) : dayjs(departureDate)}
									format="DD-MM-YYYY"
									value={dayjs(value)}
								/>
							)}
						/>
					))}
				</LocalizationProvider>
			</CommonSearchWrapper>
		</>
	);
};