import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePorts } from '@/hooks/fetch-hooks/use-ports';
import { useSpotRates } from '@/providers/spot-rates/context';
import { SearchButtonWrapper, SearchInputSectionWrapper, ShippingSearchWrapper } from './styled';
import { SearchFormData } from './types';
import { ContainerDetails, DateBookDetails, LocationDetails } from './components';
import { useSearch } from '@/hooks/fetch-hooks/use-search';
import { useAuthContext } from '@/providers';

export const ShippingSearch: React.FC<{ history?: SearchFormData }> = ({ history }) => {
	const { getPorts, ports, setPorts } = usePorts();
	const { user } = useAuthContext();
	const { 
		departureDate, setDepartureDate, 
		 setArrivalDate, 
		containerType, setContainerType, 
		spotRates, setSpotRates,
		formDefault, setFormDefault
	} = useSpotRates();
	const { register, handleSubmit, setValue, control, formState: { isValid, errors } } = useForm<SearchFormData>({
		defaultValues: history || formDefault,
	});
	const navigate = useNavigate();

	const { t } = useTranslation();
	const { getShipments } = useSearch();

	const onSubmit = async (formData: SearchFormData): Promise<void> => {
		setSpotRates([]);
		getShipments({...formData, userId: user?._id || null});
		console.log('spotRates', spotRates);
		console.log(formData);
		
		setFormDefault(formData);
		if (window.location.pathname !== '/user/new-search') {
			navigate('/request-shipping');
		}
	};

	return (
		<ShippingSearchWrapper
			component="form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<SearchInputSectionWrapper>
				<LocationDetails 
					control={control} 
					getPorts={getPorts} 
					ports={ports} 
					setPorts={setPorts}
					errors={errors}
					isHistory={!!history} 
				/>
				<DateBookDetails 
					control={control} 
					register={register} 
					setValue={setValue} 
					setDepartureDate={setDepartureDate} 
					setArrivalDate={setArrivalDate}
					departureDate={departureDate}
					errors={errors} />
				<ContainerDetails 
					control={control} 
					register={register} 
					containerType={containerType} 
					setContainerType={setContainerType}
					errors={errors} 
					isHistory={!!history}
				/>
			</SearchInputSectionWrapper>
			<SearchButtonWrapper>
				<Button
					variant='contained'
					sx={{ borderRadius: '6px', height: '80%' }}
					color='primary'
					type='submit'
					disabled={!isValid}
					startIcon={ <SearchIcon /> }
				>
					{t('common.buttons.search')}
				</Button>
			</SearchButtonWrapper>
		</ShippingSearchWrapper>
	);
};

