import { ChevronLeft } from '@mui/icons-material';
import { styled } from '@mui/material';
import { FC, ReactNode } from 'react';

const Wrapper: FC<{ children: ReactNode }> = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	position: 'relative',
	padding: theme.spacing(0, 1),
	width: '100%',
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	height: '86px',
	borderBottom: '1px solid #919191'
}));

interface IProps {
	open: boolean;
	closeDrawer: () => void;
}

export const DrawerHeader: FC<IProps> = ({ open, closeDrawer }) => (
	<Wrapper>
		<ChevronLeft
			onClick={closeDrawer} aria-label="toggle" aria-labelledby="toggle"
			sx={{
				transform: `rotate(${open ? 0 : 180}deg)`,
				transition: 'all 0.3s ease-in',
				fontSize: '40px',
				'&:hover': {
					cursor: 'pointer',
				}
			}}
		/>
	</Wrapper>
);