import { ErrorRounded } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';

export const AppError: FC = () => (
	<Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
		<Grid container spacing={2}>
			<Grid container item xs={12} justifyContent="center">
				<ErrorRounded color="error" sx={{ fontSize: 50 }} />
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="h4">Something went wrong!</Typography>
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="h6">Please try again later or try to refresh the page.</Typography>
			</Grid>
		</Grid>
	</Box>
);
