import { Logout } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '@/providers';

interface IDrawerFooter {
    isOpen: boolean;
}

export const DrawerFooter: FC<IDrawerFooter> = ({ isOpen }) => {
	const { signOut } = useAuthContext();
	const navigate = useNavigate();
	const handleLogout = (): void => {
		signOut();
		navigate('/auth');
	};

	return (
		<Box pl={isOpen ? 1 : '10px'} pt={2}
			sx={{ borderTop: '1px solid #919191'}}>
			<Button onClick={() => handleLogout()} fullWidth sx={{ justifyContent: 'start'}}>
				<Logout />
				{isOpen && <Typography>LOGOUT</Typography>}
			</Button>
		</Box>
	);
};