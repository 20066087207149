import { FC, ReactNode, useEffect } from 'react';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { notify } from '@/utils';

export const NotificationsProvider: FC<{ children?: ReactNode }> = ({ children = undefined }) => {
	useEffect(() => {
		notify.enable(
			(message: string) => enqueueSnackbar(message, { variant: 'error' }),
			(message: string) => enqueueSnackbar(message, { variant: 'warning' }),
			(message: string) => enqueueSnackbar(message, { variant: 'info' }),
			(message: string) => enqueueSnackbar(message, { variant: 'success' }),
		);
	}, []);

	return (
		<>
			<SnackbarProvider
				autoHideDuration={3500}
				preventDuplicate
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			/>
			{children}
		</>
	);
};
