import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { CookieConsentModal, MainLayout } from '@/components';
import { useAuthContext } from '@/providers';
import { IRouteProps } from '../types';

export const UserModeratorCommonRoute: FC<IRouteProps> = ({ redirectPath }) => {
	const { user } = useAuthContext();
	if (user?.role === 'user' || user?.role === 'moderator' && user?.status === 'active')
		return (
			<MainLayout>
				<Outlet />
				<CookieConsentModal />
			</MainLayout>
		);

	return <Navigate to={redirectPath} replace />;
};
