import { AxiosResponse } from 'axios';
import request from './config';
import { CONTAINERRATE_API_URL } from './constants';

export const get = (endpoint: string): Promise<AxiosResponse<any, any>> =>
	request.get(`${CONTAINERRATE_API_URL}/${endpoint}`);
export const post = <req, res>(endpoint: string, data?: req): Promise<AxiosResponse<any, any>> =>
	request.post<res>(`${CONTAINERRATE_API_URL}/${endpoint}`, data);
export const edit = <T>(endpoint: string, data?: T): Promise<AxiosResponse<any, any>> =>
	request.patch<T>(`${CONTAINERRATE_API_URL}/${endpoint}`, data);
export const del = (endpoint: string): Promise<AxiosResponse<any, any>> =>
	request.delete(`${CONTAINERRATE_API_URL}/${endpoint}`);
