import { combineReducers } from '@reduxjs/toolkit';
import { reducers } from '@/store';
import { AppState } from './config';
import { IUtilsSlice } from '@/store/utils/types';

export const rootReducer = combineReducers(reducers);

/**
 * getSelectors
 * @returns A collection of selectors for each slice of the state
 */
function getSelectors(): {
  utils: (state: AppState) => IUtilsSlice;
  } {
	const selectors = {};
	const slicesKeys = Object.keys(reducers).filter((el) => el !== '_persist');
	for (const key of slicesKeys) {
		const parsedKey = key as keyof AppState;
		Object.assign(selectors, {
			[parsedKey]: (state: AppState) => state[parsedKey],
		});
	}
	return selectors as {
    [K in keyof Omit<AppState, '_persist'>]: (state: AppState) => (typeof state)[K];
  };
}

export const selectors = getSelectors();
