import { Avatar, Box, ListItem, Typography } from '@mui/material';
import { FC } from 'react';
import { getTimeElapsed, uuid } from '@/utils/functions';
import { INotification } from '../component';

interface INotificationItemProps {
  notification: INotification;
}

const NotificationItem: FC<INotificationItemProps> = ({
	notification,
}) => (
	<ListItem key={uuid()} sx={{ p: 2, borderBottom: '1px solid #bdbdbd' }}>
		<Box display="flex" alignItems="center">
			<Avatar>{notification.teamMemberName[0]}</Avatar>
			<Box ml={2}>
				<Typography variant="body1">
					{notification.teamMemberName} {notification.action}{' '}
					{notification.taskName}
				</Typography>
				<Typography variant="body2">
					{getTimeElapsed(
						notification.timestamp,
					)}
				</Typography>
			</Box>
		</Box>
	</ListItem>
);

export default NotificationItem;
