import { Avatar, Box, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuthContext } from '@/providers';
import { HeaderRightSide, HeaderUserName, StyledBadge } from '../styled';
import { Notifications } from './notifications/component';
import TranslationDropdown from '@/components/translation-dropdown';
import stringAvatar from '@/views/user/profile/components/user-main-info/functions';

export const UserSide: FC = () => {
	const { user } = useAuthContext();
	const userName = `${user?.firstName} ${user?.lastName}`;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>):void => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = ():void => {
		setAnchorEl(null);
	};

	return (
		<Box>
			{isMobile ? (
				<>
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
						<MenuIcon />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					>
						<MenuItem>
							<StyledBadge
								overlap="circular"
								anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
								variant="dot"
							>
								<Avatar alt={'avatar'} {...stringAvatar(userName, '40px')} />
							</StyledBadge>
							<HeaderUserName sx={{marginLeft: '10px'}}>{userName.toUpperCase()}</HeaderUserName>
						</MenuItem>
						<MenuItem>
							<TranslationDropdown />
						</MenuItem>
					</Menu>
				</>
			) : (
				<HeaderRightSide>
					<Notifications />
					<StyledBadge
						overlap="circular"
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						variant="dot"
					>
						<Avatar alt={'avatar'} {...stringAvatar(userName, '40px')} />
					</StyledBadge>
					<HeaderUserName>{userName.toUpperCase()}</HeaderUserName>
					<TranslationDropdown />
				</HeaderRightSide>
			)}
		</Box>
	);
};
