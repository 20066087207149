import { FC, useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '@/providers';
import TranslationDropdown from '@/components/translation-dropdown';

export const ProfileMenu: FC = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { signOut } = useAuthContext();
	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const createHandleMenuClick = (menuItem: string) => (): void => {
		console.log(`Clicked on ${menuItem}`);
		signOut();
		handleClose();
	};

	const handleProfileClick = (): void => {
		navigate('/user/profile');
	};

	return (
		<Box>
			<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
				<PersonIcon />
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleProfileClick}>Profile</MenuItem>
				<MenuItem>
					<TranslationDropdown />
				</MenuItem>
				<MenuItem onClick={createHandleMenuClick('Log out')}>Log out</MenuItem>
			</Menu>
		</Box>
	);
};