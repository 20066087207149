import { FC } from 'react';
import { StyledCard } from './styled';
import { ICardCRProps } from './types';

export const CardCR: FC<ICardCRProps> = ({
	children,
	width,
	height,
	column,
	justifyCenter,
	justifyEnd,
	alignStart,
	overflowY,
	transparent
}) => (
	<StyledCard 
		width={width}
		height={height}
		column={column}
		justifyCenter={justifyCenter}
		justifyEnd={justifyEnd}
		alignStart={alignStart}
		overflowY={overflowY}
		transparent={transparent}
	>
		{children}
	</StyledCard>
);
