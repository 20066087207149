/* eslint-disable react/require-default-props */
import { Box, Card, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

interface IWidgetSummaryProps {
	title: string;
	icon: string;
	sx?: React.CSSProperties;
	total: string;
}

export const WidgetSummary: FC<IWidgetSummaryProps> = 
({ title, total, icon, sx, ...other }): JSX.Element => (
	<Card
		component={Stack}
		spacing={3}
		direction="row"
		sx={{
			display: 'flex',
			flexWrap: 'wrap',
			px: 3,
			py: 5,
			m: 1,
			borderRadius: 2,
			...sx,
		}}
		{...other}
	>
		{icon && <Box component='img' src={icon} sx={{ width: 64, height: 64 }}/>}

		<Stack spacing={0.5}>
			<Typography variant="h4">{total}</Typography>

			<Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
				{title}
			</Typography>
		</Stack>
	</Card>
);
