import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import PublicIcon from '@mui/icons-material/Public';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TvIcon from '@mui/icons-material/Tv';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ReactElement } from 'react';

export const service = [
	{
		logo: (): ReactElement => <DirectionsBoatIcon/>,
		name: 'Freight Services',
		description: 'We provide the best freight services in the world.'
	},
	{
		logo: (): ReactElement => <PublicIcon/>,
		name: 'Shipping & Logistics',
		description: 'We provide the best shipping and logistics services in the world.'
	},
	{
		logo: (): ReactElement => <BusinessCenterIcon/>,
		name: 'Business services',
		description: 'We provide the best business services in the world.'
	},
	{
		logo: (): ReactElement => <TvIcon/>,
		name: 'Digital Technologies',
		description: 'We provide the best digital technologies services in the world.'
	},
	{
		logo: (): ReactElement => <SupportAgentIcon/>,
		name: '24/7 Support',
		description: 'We provide the best 24/7 support services in the world.'
	}
];
