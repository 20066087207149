import { DeleteOutline } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import { SimpleDialogTransition } from '../simple-dialog-transition';
import { IDeleteModalProps } from './types';

export const DeleteModal: FC<IDeleteModalProps> = ({ 
	children, 
	onDelete, 
	title, 
	isIcon=true,
	buttonProps,
}) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleDelete = (): void => {
		onDelete();
		setOpen(false);
	};

	return (
		<>
			{isIcon ? 
				<IconButton onClick={handleClickOpen}>
					<DeleteOutline />
				</IconButton> : 
				<Button variant='contained' onClick={handleClickOpen} {...buttonProps}>
					Delete
				</Button>
			}
			<SimpleDialogTransition open={open} setOpen={setOpen} title={title}
				ctaSection={<>
					<Button variant='outlined' onClick={handleClose}>NO</Button>
					<Button variant='contained' onClick={handleDelete}>YES</Button>
				</>}
			>
				{children}
			</SimpleDialogTransition>
		</>
	);
};