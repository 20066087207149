import { Box, Button, Drawer, List, ListItem, Typography } from '@mui/material';
import React, { useState } from 'react';
// import { StyledCustomDrawer } from './styled';

interface DrawerRandomPorops {
  content: React.ReactNode;
  showButtonLabel?: string;
  additionalButton?: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
}

export const CustomDrawer: React.FC<DrawerRandomPorops> = ({
	content,
	showButtonLabel = '',
	additionalButton = null,
	isOpen: controlledIsOpen = false,
	onClose = (): void => {},
	title = '',
}) => {
	const [internalIsOpen, setInternalIsOpen] = useState(false);
	const isDrawerOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : internalIsOpen;

	const openDrawer = (): void => {
		setInternalIsOpen(true);
	};

	const closeDrawer = (): void => {
		setInternalIsOpen(false);
		if (onClose) {
			onClose();
		}
	};

	return (
		<Box>
			{showButtonLabel && (
				<Button variant="contained" onClick={openDrawer}>
					{showButtonLabel}
				</Button>
			)}
			<Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
				<List
					style={{
						width: '400px',
						margin: '10px',
						borderRadius: '16px',

					}}
				>
					{showButtonLabel && <Typography variant="h4">{showButtonLabel}</Typography>}
					{title && <Typography variant="h4">{title}</Typography>}
					{content}
					{additionalButton && <ListItem>{additionalButton}</ListItem>}
				</List>
			</Drawer>
		</Box>
	);
};
