/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode,  useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { SpotRatesContext } from './context';

export const SpotRatesProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [spotRates, setSpotRates] = useState<any>([]);
	const [originFrom, setOriginFrom] = useState<any>('');
	const [destinationTo, setDestinationTo] = useState<any>('');
	const [commodity, setCommodity] = useState<string>('');
	const [departureDate, setDepartureDate] = useState<any>(dayjs().format('YYYY-MM-DD'));
	const [arrivalDate, setArrivalDate] = useState<any>(dayjs().format('YYYY-MM-DD'));
	const [bookDateType, setBookDateType] = useState<string>('');
	const [containerType, setContainerType] = useState<string>('');
	const [weight, setWeight] = useState<number>(0);
	const [numberOfContainers, setNumberOfContainers] = useState<number>(0);
	const [formDefault, setFormDefault] = useState<number>(0);

	// Memoize the context value
	const value = useMemo(
		() => ({
			spotRates,
			setSpotRates,
			originFrom,
			setOriginFrom,
			destinationTo,
			setDestinationTo,
			commodity,
			setCommodity,
			departureDate,
			setDepartureDate,
			arrivalDate,
			setArrivalDate,
			bookDateType,
			setBookDateType,
			containerType,
			setContainerType,
			weight,
			setWeight,
			numberOfContainers,
			setNumberOfContainers,
			formDefault,
			setFormDefault,
		}),
		[spotRates, originFrom, destinationTo, 
			commodity, bookDateType, containerType, 
			departureDate, arrivalDate, weight, numberOfContainers, formDefault],
	);

	return <SpotRatesContext.Provider value={value}>{children}</SpotRatesContext.Provider>;
};