import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FooterMockData } from '@/mock';
import { uuid } from '@/utils';
import FooterWrapper from './styled';

export const Footer: FC = () => {

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<FooterWrapper>
			{
				FooterMockData.map((item) => (
					<Box width="100%" key={item.name} margin="1">
						<Typography variant="h5" align="center">
							{item.name}
						</Typography>
						<Box 
							sx={{
								width: '100%',
								display: 'flex', 
								marginTop: '50px',
								flexDirection: isMobile ? 'column' : 'row', 
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							<Box 
								sx={{
									display: 'flex',
									flexDirection: isMobile ? 'column' : 'row',
									alignItems: 'center',
									justifyContent: isMobile ? 'center' : 'flex-start',
									width: '100%',
									gap: 2
								}}>
								{item.children.map((child) => (
									<Link key={uuid()} 
										style={{ textDecoration: 'none', color: '#000' }} to={child.link}>
										<Typography variant='body2'>
											{child.title}
										</Typography>
									</Link>
								))}
							</Box>
							<Box 
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: isMobile ? 'center' : 'flex-end',
									width: isMobile ? '100%' : 'auto',
									marginTop: isMobile ? 2 : 0,
								}}>
								<Typography variant='body2' sx={{ textAlign: isMobile ? 'center' : 'right' }}>
									© 2024 Walmih Container Rate. All rights reserved.
								</Typography>
							</Box>
						</Box>
					</Box>
				))
			}
		</FooterWrapper>
	);
};
