import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Autocomplete, Typography, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@/hooks';
import { get } from '@/utils';
import { CommonSearchWrapper } from '../styled';
import { containerOptions } from './data';
import { ContainerDetailsProps } from '../../types';

export const ContainerDetails: React.FC<ContainerDetailsProps> = 
({ control, register, containerType, setContainerType, errors, isHistory }) => {
	const [inputCommodityValue, setInputCommodityValue] = useState<string>('');
	const [selectedCommodityValue, setSelectedCommodityValue] = useState<any | null>(null);
	const [commodities, setCommodities] = useState<any[]>([]);
	const debouncedInputCommodityValue = useDebounce(inputCommodityValue, 400);

	const { t } = useTranslation();

	useEffect(() => {
		if (debouncedInputCommodityValue && debouncedInputCommodityValue !== selectedCommodityValue?.label 
				&& debouncedInputCommodityValue.length > 1 && !isHistory) {
			(async (): Promise<void> => {
				try {
					const response = await get(`api/common/commodities?commodityName=${debouncedInputCommodityValue}`);
					const data = await response?.data;
					setCommodities(data?.commodities || []);
				} catch (error) {
					console.log(error);
				}
			})();
		}
	}, [debouncedInputCommodityValue, selectedCommodityValue, isHistory]);

	return (
		<>
			<Box textAlign='center'>
				<Typography variant='h6'>{t('components.search.containerDetails.title')}</Typography>
			</Box>
			<CommonSearchWrapper isContainerDetails>
				<FormControl sx={{ width: '100%' }}>
					<InputLabel id="container-number-select-label">
						{t('components.search.containerDetails.inputs.type')}
					</InputLabel>
					<Select
						{...register('containerType', { required: true })}
						labelId="container-type-select-label"
						id="container-type-select"
						label="Container Type"
						onChange={(event) => setContainerType(event.target.value as string)}
						value={containerType}
					>
						{containerOptions.map((option) => (
							<MenuItem key={option.isoCode} value={option.isoCode}>{option.label}</MenuItem>
						))}
					</Select>
					<TextField
						{...register('quantity', { 
							required: 'Quantity is required', 
							pattern: {
								value: /^[0-9]+(\.[0-9]+)?$/,
								message: 'Quantity must be a number',
							}
						})}
						label={t('components.search.containerDetails.inputs.quantity')}
						error={!!errors.quantity}
						helperText={errors.quantity ? errors.quantity.message : ''}
						variant="outlined"
						sx={{ width: '100%', marginTop: '15px' }}
					/>
					<TextField
						{...register('weight', { 
							required: 'Weight is required', 
							pattern: {
								value: /^[0-9]+$/,
								message: 'Weight must be a number',        
							} 
						})}
						error={!!errors.weight}
						helperText={errors.weight ? errors.weight.message : ''}
						label={t('components.search.containerDetails.inputs.weight')}
						variant="outlined"
						sx={{ width: '100%', marginTop: '15px' }}
					/>
				</FormControl>
				<Controller
					name="commodities"
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							options={commodities || []}
							onChange={(_, newValue) => {
								onChange(newValue || null);
								setSelectedCommodityValue(newValue || null);
							}}
							onInputChange={(_, newInputValue) => {
								if (newInputValue !== selectedCommodityValue?.label) {
									setInputCommodityValue(newInputValue);
								}
							}}
							onClose={() => setInputCommodityValue('')}
							value={value || null}
							sx={{ width: '100%'}}
							isOptionEqualToValue={(option, optionValue) => 
								option !== optionValue}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t('components.search.containerDetails.inputs.commodities')}
									onFocus={() => setCommodities([])}
									variant="outlined"
									sx={{ border: 'none' }}
								/>
							)}
						/>
					)}
				/>
                
			</CommonSearchWrapper>
		</>
	);
};
