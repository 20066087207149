import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { CardCR } from '@/components/custom-card';
import { ICardCRProps } from '@/components/custom-card/types';
import orangeShapes from '../../../assets/orangeShapes.png';

export const AuthWrapper: FC<ICardCRProps> = ({ children }) =>{ 
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md')); 

	return (
		<Box 
			display={'flex'} 
			justifyContent={'center'} 
			alignItems={'center'} 
			height={'100%'}
			width={'100%'}
			padding={isMobile ? theme.spacing(2) : 0}
		>
			<Box
				component={'img'}
				src={orangeShapes}
				sx={{ filter: 'blur(20px)'}}
				style={{ position: 'absolute', zIndex: -1, width: '100vw', height: '100vh'}}
			/>
			<CardCR transparent width={isMobile ? '100%' : '80%'} 
				height={isMobile ? 'auto' : '70%'} display="flex" justifyCenter>
				<Grid item xs={12} md={!isMobile ? 12 : 6} style={{ alignSelf:'center' }}>
					{children}
				</Grid>
			</CardCR>
		</Box>
	);
};