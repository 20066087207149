import { AxiosError } from 'axios';
// import { AdminsMock } from '@/mock';
import { del, edit, get, post } from '@/utils';
import { IAdmins, ICreateAdmin, IUpdateAdmin } from './types';

async function fetchAdmins(): Promise<IAdmins[]> {
	try {
		const res = await get('api/protected/admins');
		const data = res.data as unknown as { admins: IAdmins[] };
		return data?.admins || [];

	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get admins.');
	}
}

// async function getTempAdmin(adminId: string): Promise<IAdmins> {
// 	try {
// 	  // Simulating finding the user directly from the UsersMock array
// 	  const admin = AdminsMock.find(el => el._id === adminId);
// 	  if (!admin) {
// 			throw new Error(`Admin with ID ${adminId} not found.`);
// 	  }
// 	  return admin;
// 	} catch (e) {
// 	  // Using a more concise way to express the error message fallback
// 	  throw new Error('Failed to get Admin.');
// 	}
// }

async function fetchSingleAdmin(adminId: string): Promise<IAdmins> {
	try {
		const res = await get(`api/protected/admins/${adminId}`);
		const data = res.data as unknown as { admin: IAdmins };
		return data?.admin || {};

		// SIMULATE FETCHING
		// eslint-disable-next-line
		// await new Promise(resolve => setTimeout(resolve, 200));
		// const res = await getTempAdmin(adminId);
		// const data = {admin: res} as unknown as { admin: IAdmins };
		// return data?.admin || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get admin.');
	}
}

async function deleteAdmin(adminId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/admins/${adminId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to delete admin.');
	}
}

async function postAdmin(adminObject: ICreateAdmin): Promise<string | number> {
	try {
		const res = await post('api/protected/admins', {...adminObject});
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to create admin.');
	}
}

async function patchAdmin(adminId: string, updatedAdmin: IUpdateAdmin): Promise<string | number> {
	try {
		const res = await edit(`api/protected/admins/${adminId}`, {...updatedAdmin});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to update admin.');
	}
}

export { deleteAdmin, fetchAdmins, fetchSingleAdmin, patchAdmin, postAdmin };

