import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

const ShippingSearchWrapper = styled(Box as FC<BoxProps>)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	gap: theme.spacing(6),
	width: '100%',
	height: 'auto',
	backgroundColor: '#fff',
	borderRadius: '10px',
	padding: theme.spacing(1),
	boxShadow: theme.shadows[5],
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
}));

const SearchInputSectionWrapper = styled(Box as FC<BoxProps>)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '80%',
	height: '100%',
	[theme.breakpoints.down('md')]: {
		margin: 0,
		width: '100%',
	},
}));

const SearchButtonWrapper = styled(Box as FC<BoxProps>)(({ theme }) => ({
	width: '20%', 
	height: '100%',
	display: 'flex', 
	justifyContent: 'center', 
	alignItems: 'center',
	[theme.breakpoints.down('md')]: {
		width: '100%',
		margin : 'auto',
		marignBottom: theme.spacing(2),
	},
}));

export { ShippingSearchWrapper, SearchButtonWrapper, SearchInputSectionWrapper };