import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FC, useState } from 'react';
import { Box } from '@mui/material';
import i18n from '@/translate/i18n';
import { actions, useAppDispatch } from '@/utils';

export const TranslationDropdown: FC = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const dispatch = useAppDispatch();

	const handleLanguage = (value: string): void => {
		i18n.changeLanguage(value);
		dispatch(actions.changeLanguage(value));
	};

	return (
		<Box>
			<Button aria-describedby={id} onClick={handleClick}>
				Language
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Typography sx={{ p: 2 }}>
					{['ro', 'en', 'es'].map((language: string) => (
						<Button key={language} onClick={() => handleLanguage(language)}>
							<Typography>
								{language.toUpperCase()}
							</Typography>
						</Button>
					))}
				</Typography>
			</Popover>
		</Box>
	);
};
