import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Typography, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@/hooks';
import { LocationDetailsProps } from '../../types';
import { CommonSearchWrapper } from '../styled';

/* ISOEquipmentCode
: 
"22G1"
arrivalDate
: 
"2024-06-28"
cargoType
: 
"DRY"
collectionOriginCityName
: 
"Shanghai"
collectionOriginCountryCode
: 
"CN"
collectionOriginUNLocationCode
: 
"CNSHA"
collectionOriginUNRegionCode
: 
"SH"
deliveryDestinationCityName
: 
"Constanta"
deliveryDestinationCountryCode
: 
"RO"
deliveryDestinationUNLocationCode
: 
"ROCND"
departureDate
: 
"2024-06-12"
quantity
: 
2
weight
: 
2222

*/
export const LocationDetails: React.FC<LocationDetailsProps> = ({ control, getPorts, ports, setPorts, isHistory }) => {
	const [inputValue, setInputValue] = useState<string>('');
	const [selectedValue, setSelectedValue] = useState<any | null>(null);
	const debouncedInputValue = useDebounce(inputValue, 400);

	const { t } = useTranslation();

	useEffect(() => {
		if (debouncedInputValue && debouncedInputValue !== selectedValue?.label && !isHistory) {
			getPorts(debouncedInputValue);
		}
	}, [debouncedInputValue, getPorts, selectedValue, isHistory]);

	return (
		<>
			<Box textAlign='center'>
				<Typography variant='h6'>{t('components.search.locationsDetails.title')}</Typography>
			</Box>
			<CommonSearchWrapper>
				{['from', 'to'].map((field) => (
					<Controller
						key={field}
						name={field}
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, value } }) => (
							<Autocomplete
								options={ports || []}
								onChange={(_, newValue) => {
									onChange(newValue || null);
									setSelectedValue(newValue || null);
								}}
								onInputChange={(_, newInputValue) => {
									if (newInputValue !== selectedValue?.label) {
										setInputValue(newInputValue);
									}
								}}
								onClose={() => setInputValue('')}
								value={value || null}
								sx={{ width: '100%' }}
								isOptionEqualToValue={(option, optionValue) => 
									option.label !== optionValue.label}
								renderInput={(params) => (
									<TextField
										{...params}
										label={field === 'from' ? t('components.search.locationsDetails.inputs.origin')
										 : t('components.search.locationsDetails.inputs.destination')}
										onFocus={() => setPorts([])}
										variant="outlined"
										sx={{ border: 'none' }}
									/>
								)}
							/>
						)}
					/>
				))}
			</CommonSearchWrapper>
		</>
	);
};
