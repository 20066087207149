import { AxiosError } from 'axios';
import { post } from '@/utils';
import { SearchFormData } from '@/components/shipping-search/types';

async function 	searchShipments(formData: SearchFormData): Promise<any> {
	try {
		const res = await post('api/common/search', {
			collectionOriginCountryCode: formData.from.countryCode,
			collectionOriginCityName: formData.from.cityName,
			collectionOriginUNLocationCode: formData.from.UNLocationCode,
			collectionOriginUNRegionCode: formData.from.UNRegionCode,
			deliveryDestinationCountryCode: formData.to.countryCode,
			deliveryDestinationCityName: formData.to.cityName,
			deliveryDestinationUNLocationCode: formData.to.UNLocationCode,
			deliveryDestinationUNRegionCode: formData.to.UNRegionCode,
			ISOEquipmentCode: formData.containerType,
			departureDate: formData.departureDate,
			arrivalDate: formData.arrivalDate,
			weight: Number(formData.weight),
			quantity: Number(formData.quantity),
			userId: formData.userId,
			cargoType: formData.commodities?.cargoTypes[0] === 'REEFER' ? 'REEF' : 'DRY',
		});
		const { data } = res;
		return data;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get shipments.');
	}
}

async function searchShipmentsCmaCgm(formData: SearchFormData): Promise<any> {
	try {
		const res = await post('api/common/search-cma-cgm', {
			collectionOriginUNLocationCode: formData.from.UNLocationCode,
			deliveryDestinationUNLocationCode: formData.to.UNLocationCode,
			departureDate: formData.departureDate,
			arrivalDate: formData.arrivalDate,
			ISOEquipmentCode: formData.containerType,
		});
		const { data } = res;
		console.log('data', data);
		return data;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get shipments.');
	}
}

export { searchShipments, searchShipmentsCmaCgm };