import { useCallback, useState } from 'react';
import { notify } from '@/utils';
import { SearchReturnType } from './types';
import { searchShipments } from './actions';
import { SearchFormData } from '@/components/shipping-search/types';
import { useSpotRates } from '@/providers/spot-rates/context';

export function useSearch(
): SearchReturnType {
	const { setSpotRates } = useSpotRates();
	const [loading, setLoading] = useState<boolean>(false);

	const getShipments = useCallback(
		async (formData: SearchFormData ) => {
			try {
				setLoading(true);
				const response = await searchShipments(formData);
				setSpotRates(response.data);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		[setSpotRates]
	);

	return {
		loading,
		getShipments,
	};
}
