import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

const FooterWrapper = styled(Box as FC<BoxProps>)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	gap: theme.spacing(6),
	padding: '10%',
	[theme.breakpoints.down('md')]: {
		position: 'relative',
		marginTop: theme.spacing(30)
	},
}));

export default FooterWrapper;
