import { Card, CardProps, styled } from '@mui/material';
import { FC } from 'react';

export const StyledContentCard = styled(Card as FC<CardProps>)
(({ theme }) => ({
	maxWidth: theme.spacing(100),
	width: '100%',
	minWidth: theme.spacing(27),
	margin: theme.spacing(2, 0),
	cursor: 'pointer',
	borderRadius: theme.spacing(2),
	border: '#FDC50C solid 1px',
	'&:hover': {
		boxShadow: theme.shadows,
	},
}));