import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { FlexProps } from '../../flex';
import { HeroWrapperContainer } from './styled';

interface IHeroWrapperProps {
    imageSrc: string;
    children?: ReactNode;
    contentProps?: FlexProps;
}

export const HeroWrapper: FC<IHeroWrapperProps> = ({ imageSrc, children = undefined, contentProps = undefined }) => (
	<HeroWrapperContainer>
		<Box component={'img'} alt='section-hero' src={imageSrc} sx={{
			width: '100%', height: '113px', objectFit: 'cover'
		}}/>
		<Box px={3} pt={2} sx={{ maxHeight: 'calc(100% - 140px)', overflowY: 'auto'}} {...contentProps}>
			{children}
		</Box>
	</HeroWrapperContainer>
);
