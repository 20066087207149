import { FC, ReactNode } from 'react';
import { useAppSelector } from '@/utils';
import { Header } from '../../header';
import { SideDrawer } from '../../side-drawer';
import { MainLayoutBase, MainLayoutContent } from './styled';

interface MainLayoutProps {
  children?: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children = undefined }) => {
	const { isDrawerOpen } = useAppSelector((state) => state.utils);

	return (
		<MainLayoutBase isDrawerOpen={isDrawerOpen}>
			<SideDrawer />
			<Header />
			<MainLayoutContent>
				{children}
			</MainLayoutContent>
		</MainLayoutBase>
	);
};

