import { styled, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const PageTitle = styled(Typography as FC<TypographyProps>)(({ theme }) => ({
	width: 'auto',
	height: '56px',
	fontSize: '48px',
	lineHeight: '56px',
	color: theme.palette.primary.dark,
	[theme.breakpoints.down('md')]: {
		fontSize: '32px',
		lineHeight: '40px',
		marginBottom: theme.spacing(7),

	},
}));