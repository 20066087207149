import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const Logo: FC<{ customHeight?: string }> = ({ customHeight = '100px' }): JSX.Element => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

	let logoHeight = customHeight;
	if (isMobile) {
		logoHeight = '50px';
	} else if (isTablet) {
		logoHeight = '60px';
	} else if (isDesktop) {
		logoHeight = '60px';
	}

	return (
		<Box 
			height={logoHeight} 
			onClick={() => navigate('/')} 
			component='img' 
			src='https://storage.googleapis.com/sbdcloud/logo-containerrate.png' 
			sx={{ cursor: 'pointer' }}
		/>
	);
};
