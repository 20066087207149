import { Box, Button, List, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import NotificationIcon from './notification-icon/component';
import { CustomDrawer } from '@/components/drawer';
import notifications from '../../../../mock/notifications.mock.json';
import NotificationItem from './notification-item/component';
import { ViewNotificationsWrapper } from './styled';
import { uuid } from '@/utils';

export interface INotification {
  teamMemberName: string;
  action: string;
  taskName: string;
  timestamp: string;
}

export const Notifications: FC = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const anchorEl = useRef<HTMLButtonElement | null>(null);

	const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
		anchorEl.current = event.currentTarget;
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	return (
		<Box>
			<NotificationIcon
				showPulse={!!notifications.length}
				handleClickOpen={handleClickOpen}
			/>
			<CustomDrawer
				isOpen={open}
				onClose={handleClose}
				content={
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							height: '96vh',
							width: '100%',
							overflowY: 'hidden',
						}}
					>
						<Typography variant="h4" textAlign="center">
							Notificari
						</Typography>
						<List
							sx={{
								scrollbarWidth: 'thin',
								scrollbarColor: '#888888 #f5f5f5',
								'&::-webkit-scrollbar': {
									width: '6px',
									backgroundColor: '#f5f5f5',
								},
								borderRadius: '16px',
								'&::-webkit-scrollbar-thumb': {
									backgroundColor: '#888888',
									borderRadius: '3px',
								},
								height: '100%',
								width: '100%',
								overflow: 'auto',
							}}
						>
							{notifications.length === 0 ? (
								<Typography>
									Nu exista notificari
								</Typography>
							) : (
								(notifications as INotification[])
									.sort(
										(a, b) =>
											new Date(b.timestamp).getTime() -
                      						new Date(a.timestamp).getTime(),
									)
									.map((notification: INotification, index: number) => (
										<NotificationItem
											key={uuid()}
											{...{ notification, index }}
										/>
									))
							)}
						</List>
						<ViewNotificationsWrapper>
							<Button variant="contained">
								<Typography>
									Toate notificarile
								</Typography>
							</Button>
						</ViewNotificationsWrapper>
					</Box>
				}
			/>
		</Box>
	);
};
